<template>
  <div>
    <div class="header">
      <h4>对账</h4>
      <el-button
        v-access
        data-ctrl="agentAdd"
        size="small"
        @click="roleAdd()"
        type="primary"
        icon="el-icon-plus"
      >新建</el-button>
    </div>
    <ListFilter :total="total" @close="putAway">
      <div>
        关键字：
        <el-input
          v-model="keywords"
          size="mini"
          placeholder="请输入关键字"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          @change="getSearch()"
        ></el-input>对账月份:
        <el-date-picker
          v-model="date"
          type="month"
          placeholder="选择日期时间"
          prefix-icon="el-icon-date"
          format="yyyy-MM"
          size="mini"
          style="width: 150px; border-radius: 10px"
          @change="timeChange()"
        ></el-date-picker>
        <span style="margin-left: 16px">服务中心:</span>
        <el-select
          v-model="serviceCentre"
          placeholder="请选择"
          value-key="id"
          size="mini"
          @change="getSearch()"
        >
          <el-option
            v-for="(item, index) in contractList"
            :key="index"
            :label="item.platform_name"
            :value="item.pay_platform"
          ></el-option>
        </el-select>
        <span style="margin-left: 16px">状态:</span>
        <el-select
          v-model="state"
          placeholder="请选择"
          value-key="id"
          size="mini"
          @change="getSearch()"
        >
          <el-option v-for="item in stateList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </div>
    </ListFilter>
    <el-dialog title="编辑" :visible.sync="editShow" width="50%" :before-close="handleClose">
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="110px"
      >
        <el-form-item label="公司名称" prop="enterpriseCode">
          <el-select style="width: 100%" v-model="ruleForm.enterpriseCode">
            <el-option
              v-for="(item, index) in enterpriseList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="服务中心" prop="pay_platform">
          <el-select style="width: 100%" v-model="ruleForm.pay_platform">
            <el-option
              v-for="(item, index) in contractList"
              :key="index"
              :label="item.platform_name"
              :value="item.pay_platform"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对账月份" prop="checkTime">
          <el-date-picker
            v-model="ruleForm.checkTime"
            type="month"
            placeholder="选择日期"
            prefix-icon="el-icon-date"
            format="yyyy-MM"
            style="width: 100%"
            @change="getrq(ruleForm.checkTime)"
          ></el-date-picker>
          <!-- <el-input v-model="ruleForm.serviceAddress"></el-input> -->
        </el-form-item>
        <el-form-item label="对账金额" prop="amount">
          <el-input type="number" v-model="ruleForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="审批人" prop="checker">
          <el-select style="width: 100%" filterable v-model="ruleForm.checker" @change="ShrList">
            <el-option
              v-for="item in templateList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="padding: 10px 0">
        <span>下载对账表模板，并将信息按要求填写到模板</span>
      </div>
      <div style="padding: 10px 0">
        <a
          href="https://file.lgbfss.com/hr/39976fdf37a5d9c13ae5fefae0be3c17/员工宿舍申请.xlsx"
          style="color: #409eff; text-decoration: none"
        >下载对账表模板</a>
      </div>
      <div>
        <span>下载对账表</span>
      </div>
      <div>
        <el-button type="primary" size="mini" plain style="margin: 20px 0">
          <a :href="DateList.filePath">{{ dzbName }}</a>
        </el-button>
      </div>
      <div>
        <span>将填好的模板文件上传到系统中</span>
      </div>
      <div>
        <el-upload
          class="upload-demo"
          :action="action"
          :on-change="handleChange"
          :file-list="fileList"
          :on-success="successFile"
        >
          <el-button type="primary" size="mini" plain style="margin: 20px 0">上传对账表</el-button>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="submit('ruleForm', '1', code, '编辑')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加对账" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="110px"
      >
        <el-form-item label="公司名称" prop="enterpriseCode">
          <el-select style="width: 100%" v-model="ruleForm.enterpriseCode">
            <el-option
              v-for="(item, index) in enterpriseList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="服务中心" prop="pay_platform">
          <el-select style="width: 100%" v-model="ruleForm.pay_platform">
            <el-option
              v-for="(item, index) in contractList"
              :key="index"
              :label="item.platform_name"
              :value="item.pay_platform"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对账月份" prop="checkTime">
          <el-date-picker
            v-model="ruleForm.checkTime"
            type="month"
            placeholder="选择日期"
            prefix-icon="el-icon-date"
            format="yyyy-MM"
            style="width: 100%"
            @change="getrq(ruleForm.checkTime)"
          ></el-date-picker>
          <!-- <el-input v-model="ruleForm.serviceAddress"></el-input> -->
        </el-form-item>
        <el-form-item label="对账金额" prop="amount">
          <el-input type="number" v-model="ruleForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="审批人" prop="checker">
          <el-select style="width: 100%" filterable v-model="ruleForm.checker" @change="ShrList">
            <el-option
              v-for="item in templateList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="padding: 20px 0">
        <span>下载对账表模板，并将信息按要求填写到模板</span>
      </div>
      <div>
        <a
          href="https://file.lgbfss.com/hr/39976fdf37a5d9c13ae5fefae0be3c17/员工宿舍申请.xlsx"
          style="color: #409eff; text-decoration: none"
        >下载对账表模板</a>
      </div>
      <div style="padding: 20px 0">
        <span>将填好的模板文件上传到系统中</span>
      </div>
      <div>
        <el-upload
          class="upload-demo"
          :action="action"
          :on-change="handleChange"
          :file-list="fileList"
          :on-success="successFile"
        >
          <el-button type="primary" size="mini" plain>上传对账表</el-button>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <div>
      <el-table :data="tableList" style="width: 100%；">
        <!-- <el-table-column prop="code" label="编号" align="center">
          <template slot-scope="scope">
            <div class="clickColor" @click="handleClick(scope.row)">
              {{ scope.row.code }}
            </div>
          </template>
        </el-table-column>-->
        <el-table-column label="对账名称" align="center">
          <template slot-scope="scope">
            {{ scope.row.checkTime | dateVal("YYYY年MM月") }}
            <span v-if="scope.row.enterprise">
              {{
              scope.row.enterprise.name
              }}
            </span>
            <span v-else></span>对账
          </template>
        </el-table-column>

        <el-table-column prop="pay_platform" label="服务中心" align="center">
          <template slot-scope="scope">{{ scope.row.pay_platform | platformVal }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="对账日期" align="center"></el-table-column>
        <!-- <el-table-column prop="checkTime" label="对账月份" align="center">
        </el-table-column>-->
        <el-table-column prop="amount" label="对账金额" align="center"></el-table-column>

        <el-table-column label="核算人" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.handlerInfo.name">{{ scope.row.handlerInfo.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审批人" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.checkerInfo.name">{{ scope.row.checkerInfo.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template scope="scope">
            <el-tag v-if="scope.row.state === '1'" type="info">待复核</el-tag>
            <el-tag v-if="scope.row.state === '2'" type="danger">已驳回</el-tag>
            <el-tag v-if="scope.row.state === '5'" type="success">已审批</el-tag>
            <el-tag v-if="scope.row.state === '4'" type="success">待审批</el-tag>
            <el-tag v-if="scope.row.state === '3'" type="info">待确认</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state === '1'">
              <el-button
                v-access
                data-ctrl="agent.repeatCheck"
                type="text"
                size="small"
                @click="open(scope.row.code)"
              >复核</el-button>
            </div>
            <div v-if="scope.row.state === '2'">
              <el-button
                v-access
                data-ctrl="agentEdit"
                type="text"
                size="small"
                @click="reject(scope.row.code)"
              >编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="是否通过复核" :visible.sync="openShow" width="30%" :before-close="handleClose">
        <el-radio v-model="radio" label="3">通过</el-radio>
        <el-radio v-model="radio" label="2">不通过</el-radio>
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm1"
          class="ruleForm"
          label-width="110px"
        >
          <el-form-item label="备注" prop="remark" style="width: 100%">
            <el-input v-model="ruleForm.remark"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="openShow = false">取 消</el-button>
          <el-button type="primary" @click="submit('ruleForm1', radio, code, '复核')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div style="margin-top: 12px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next,jumper, sizes"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base';
import { userList } from '../../api/index';
// import baseUrl from "../../config/config";
export const AgentDescribe = api()('agent.describe.json');
//查询
export const AgentList = api()('agent.list.json');
//上传
// export const AgentList = api()("agent.list.json");
//新增
export const AgentAdd = api()('agent.add.json');
//审核人
export const AgentShr = api()('user.describe.subuser.json');
//编辑
export const AgentEdit = api()('agent.edit.json');
export const EnterpriseSimpleList = api('/enterprise')(
  'enterprise.simple.list.json'
);

export default {
  data () {
    return {
      value: '',
      keywords: '',
      fileList: [],
      action: '',
      serviceCentreList: [
        {
          value: '服务中心1'
        },
        {
          value: '服务中心2'
        },
        {
          value: '服务中心3'
        },
        {
          value: '服务中心4'
        }
      ],
      state: '',
      radio: '3',
      openShow: false,
      stateList: [
        {
          value: '待复核',
          id: '1'
        },
        {
          value: '已驳回',
          id: '2'
        },
        {
          value: '待确认',
          id: '3'
        },
        {
          value: '待审批',
          id: '4'
        },
        {
          value: '已审批',
          id: '5'
        }
      ],

      serviceCentre: '',
      tableList: [],
      dialogVisible: false,
      date: '',
      editShow: false,
      options: [],
      type: '',
      enterpriseList: [],
      total: 0,
      ruleForm: {
        enterpriseCode: '',
        pay_platform: '',
        checkTime: '',
        amount: '',
        checker: '',
        remark: ''
      },
      rules: {
        enterpriseCode: [
          { required: true, message: '请选择企业名称', trigger: 'change' }
        ],
        pay_platform: [
          { required: true, message: '请选择服务中心', trigger: 'change' }
        ],
        checkTime: [
          { required: true, message: '请选择对账月份', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入对账金额', trigger: 'blur' }
        ],
        checker: [
          { required: true, message: '请输入处理人姓名', trigger: 'change' }
        ],
        remark: [
          {
            required: true,
            message: '请输入备注',
            trigger: 'change'
          }
        ]
      },

      contractList: [
        {
          name: '服务中心1'
        },
        {
          name: '服务中心2'
        },
        {
          name: '服务中心3'
        },
        {
          name: '服务中心4'
        }
      ],
      contentList: [],
      fileName: '',
      tableData: [],
      templateList: [
        {
          name: '张晓培'
        },
        {
          name: '王晓曦'
        },
        {
          name: '李朝朝'
        },
        {
          name: '赵助人'
        }
      ],
      platformList: [],
      subList: [],
      pageNumber: 1,
      pageSize: 10,
      filePath: '',

      shenheShow: false,
      dzbName: '',
      DateList: {},
      enterprise: {},
      handlerInfo: {},
      checkerInfo: {}
    };
  },
  components: {
    ListFilter: () => import('/src/components/filter')
  },

  mounted () {
    this.contractList = this.$route.params.$preload.platformList;
    console.log(this.contractList);
  },
  created () {
    this.getEnterpriseSimpleList();
    this.getInvoiceList();
    this.ShrList();
    // baseUrl.baseUrl + "enterprise.upload.json"
    this.action = window.location.origin + '/enterprise.upload.json';
    console.log(this.action);
  },
  methods: {
    async goEdit () {
      const result = await AgentDescribe({
        code: this.code
      });
      this.DateList = result || {};
      this.ruleForm.enterpriseCode = this.DateList.enterprise.code;
      this.ruleForm.amount = parseInt(this.DateList.amount);
      this.ruleForm.checkTime = this.DateList.checkTime;
      this.ruleForm.pay_platform = this.DateList.pay_platform;
      this.ruleForm.checker = this.DateList.checker;
      let m = this.DateList.filePath.split('/');
      this.dzbName = m[5];
      console.log(this.dzbName);
    },
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    successFile (res, fileList) {
      console.log(fileList);
      console.log(res.data);
      this.filePath = res.data;
    },

    handleSizeChange (val) {
      this.pageSize = val;
      this.getInvoiceList();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.getInvoiceList();
    },
    async AgentEdit (code, state, bz, operation) {
      var data = {};
      if (operation == '复核') {
        data = {
          code: code,
          remark: bz,
          state: state
          // enterpriseCode: this.ruleForm.enterpriseCode,
          // pay_platform: this.ruleForm.pay_platform,
          // checkTime: this.ruleForm.checkTime,
          // // amount: parseInt(this.ruleForm.amount),
          // checker: this.ruleForm.checker
          // // filePath: this.filePath
        };
      } else {
        if (this.filePath == '') {
          data = {
            code: code,
            state: state,

            enterpriseCode: this.ruleForm.enterpriseCode,
            pay_platform: this.ruleForm.pay_platform,
            checkTime: this.ruleForm.checkTime,
            amount: parseInt(this.ruleForm.amount),
            checker: this.ruleForm.checker
          };
        } else {
          data = {
            code: code,
            state: state,
            filePath: this.filePath,
            enterpriseCode: this.ruleForm.enterpriseCode,
            pay_platform: this.ruleForm.pay_platform,
            checkTime: this.ruleForm.checkTime,
            amount: parseInt(this.ruleForm.amount),
            checker: this.ruleForm.checker
          };
        }
      }

      const result = await AgentEdit(data);
      this.fileList = [];
      this.radio = '';
      console.log(result);
      this.getInvoiceList();
    },
    submit (formName, radio, code, operation) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.AgentEdit(code, radio, this.ruleForm.remark, operation);
          this.resetForm(formName);
          this.openShow = false;
          this.shenheShow = false;
          this.editShow = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    open (code) {
      this.code = code;
      this.openShow = true;
    },

    reject (code) {
      this.code = code;
      this.editShow = true;
      this.goEdit();
    },
    async getInvoiceList () {
      const result = await AgentList({
        keywords: this.keywords,
        checkTime: this.date,
        pay_platform: this.serviceCentre,
        state: this.state,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      });
      this.tableList = result.list || [];

      console.log(this.tableList);
      this.total = result.total || 0;
      this.fileList = [];
    },
    getSearch () {
      this.getInvoiceList();
    },
    timeChange () {
      this.date = this.date.getFullYear() + '-' + (this.date.getMonth() + 1);
      this.getInvoiceList();
    },
    async ShrList () {
      const result = await AgentShr({
        keywords: '',
        pageNumber: 1,
        pageSize: 1000,
        roless: ['enterprise_finance']
      });
      console.log(result);
      this.templateList = result.list;
    },

    putAway () {
      this.filterOpen = false;
      this.$emit('close');
    },

    async getEnterpriseSimpleList () {
      const enterprises = await EnterpriseSimpleList({});
      this.enterpriseList = enterprises?.list || [];
    },
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          console.log(valid);
          if (this.filePath) {
            const result = await AgentAdd({
              handler: window.localStorage.getItem('code'),
              enterpriseCode: this.ruleForm.enterpriseCode,
              pay_platform: this.ruleForm.pay_platform,
              checkTime: this.ruleForm.checkTime,
              amount: parseInt(this.ruleForm.amount),
              checker: this.ruleForm.checker,
              filePath: this.filePath
            });

            console.log(result);
            this.dialogVisible = false;
            this.resetForm(formName);
            this.filePath = '';
            this.getInvoiceList();
          } else {
            this.dialogVisible = true;
            this.$message('请上传对账表');
          }
        } else {
          return false;
        }
      });
    },

    async remoteMethod (query) {
      const users = await userList({
        keywords: query,
        pageSize: 20,
        pageNumber: 1,
        isAuthenticated: true
      });
      this.options = users.list;

      console.log(users);
    },

    getrq (date) {
      this.ruleForm.checkTime =
        date.getFullYear() + '-' + (date.getMonth() + 1);
      console.log(this.ruleForm.checkTime);
    },
    serviceCentreSelect (value) {
      console.log(value);
      this.serviceCentre = value;
    },
    stateSelect (value) {
      console.log(value);
      this.state = value;
    },
    goReset () {
      this.serviceCentre = '';
      this.state = '';
      this.date = '';
      this.getInvoiceList();
    },
    roleAdd () {
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
      this.shenheShow = false;
      this.openShow = false;
      this.editShow = false;
      this.getInvoiceList();
    },
    handleClick (row) {
      console.log(row.code);
      this.$router.push({
        path: '/system/agent/reconciliationDetails',
        query: {
          code: row.code
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.drDzb {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.downDzb {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.summa {
  display: flex;
  align-items: center;

  .reset {
    padding: 5px 15px;
    background: rgb(22 155 213);
    color: #fff;
    border-radius: 2px;
    margin: 0 20px;
  }
}
.select-border {
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}
.header {
  border-bottom: 1px solid #ebeef5;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
